<template>
  <div class="about">
    <p>
      <a href="https://stock.mephisto.cc">本站</a> 用于拉黑股票, 防止踩雷中枪.
    </p>
    <p>
      也欢迎访问我的个人站点 <a href="https://mephisto.cc">https://mephisto.cc</a>
    </p>
    <p>关注微信公众号，输入股票代码，可查询拉黑原因</p>
    <img src="@/assets/qr_wechat.webp" alt="微信公众号" width="320" height="100">
  </div>
</template>